.gallery {
  background-color: darkslategrey;
  width: 90vw;
  height: 90vh;
}

.gallery-directions {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  z-index: 50;
  position: fixed;
  padding: 40px;
  font-size: 2em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  top: 200px;
  left: 32vw;
}
